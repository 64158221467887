
@keyframes zoomInS {
  from {
    opacity: 0;
    transform: scale3d(.8, .8, .8);
  }

  50% {
    opacity: 1;
  }
}

.zoomInS {
  animation-name: zoomInS;
}

@keyframes flipps {
  0% {
    transform: rotateY(0deg);
  }
  25% {
    transform: rotateY(-180deg) translateZ(-200px);
  }
  50%
  {
    transform: rotateY(-360deg);

  }
  100%
  {
    transform: rotateY(-360deg);

  }
}

.flipps {
  animation-name: flipps;
}