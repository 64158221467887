$primary: #1565C0;
$primaryBorder: #0b3d75;

$secondary: #F0F0F0;
$secondaryBorder: #727272;

.button-roll {
  --button-roll-background: #{$primary};
  --button-roll-border-color:#{$primaryBorder};


  background-color: var(--button-roll-background)!important;
  //border: 1px solid rgba($primaryBorder, .3)  !important;
  border-bottom: 3px solid var(--button-roll-border-color)!important;
  margin-top: 0;
  transition: border-bottom 40ms ease-out,  margin 40ms ease-out!important;

  &.secondary {
    --button-roll-background: #{$secondary};
    --button-roll-border-color:#{$secondaryBorder};
    //border: 1px solid rgba($secondaryBorder, .3)  !important;
    border-bottom: 3px solid var(--button-roll-border-color)!important;
    color: #232323;
  }

  &:active {
    border-bottom-width: 1px!important;
    margin-top: 3px;
  }

}