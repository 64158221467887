html {
  height: 100%;
}

body {
  min-height: 100%;
  touch-action: pan-x pan-y;
}
#root {}

.App {
  height: 100%;
  position: fixed;
  width: 100%;
  overflow: hidden;


  background-color: #696969;
  box-shadow: inset 0 0 500px #222;

  &.crimson {
    background: url(../../assets/wood.jpg) 50% 50%
  }
}