.avatar {
  $size:90px;
  display: inline-block;
  width: $size;
  height: $size;
  border-radius: $size/2;
  background-color: #CCC;
  text-align: center;
  font-size: 50px;
  line-height: $size;
  color:#FFF;
  text-shadow: 0 1px 2px rgba(0,0,0,.3);
  background-size: cover;
  vertical-align: top;
  //border: 5px solid #039BE5;
  //box-shadow: 0 0 5px #039BE5, 0 0 5px #039BE5, 0 0 5px #039BE5, 0 0 5px #039BE5 ;
  //animation-duration: 1s;
  //animation-delay: 100ms;
  //background: url(http://api.sunny.fr/images/sleeping.jpg);

}