

@mixin clearfix() {
  &:after {
    clear: both;
    content: "";
    display: block;
  }
}

@mixin anidelay($delay, $it, $global-delay: 0 ) {
  &:nth-child(#{$it}) {
    animation-delay: $global-delay + ($delay  * ($it - 1));
  }
}

@mixin animated($time: 300ms) {
  animation-duration: 300ms;
  animation-fill-mode: both;
}

@function strip-unit($value) {
  @return $value / ($value * 0 + 1);
}

@mixin fluid-type($min-vw, $max-vw, $min-font-size, $max-font-size) {
  $u1: unit($min-vw);
  $u2: unit($max-vw);
  $u3: unit($min-font-size);
  $u4: unit($max-font-size);

  @if $u1 == $u2 and $u1 == $u3 and $u1 == $u4 {
    &  .fluid-typo  {
      font-size: $min-font-size;
      @media screen and (min-width: $min-vw) {
        font-size: calc(#{$min-font-size} + #{strip-unit($max-font-size - $min-font-size)} * ((100vw - #{$min-vw}) / #{strip-unit($max-vw - $min-vw)}));
      }
      @media screen and (min-width: $max-vw) {
        font-size: $max-font-size;
      }
    }
  }
}