

//$background-color: #4DC8C7;
$background-color: #232323;//#E9E9E9;//#CBD3E0;
$primary-color:#434343;

$header-background-color: #2191FD;//#2191FD;
$header-height: 60px;
$link-color: #FFF;

$highlight-color: #bdd64d ;
$highlight-link-color: #bdd64d ;
