@import "mixins";
body {
  background: $background-color;
  font-size: 14px;
  //font-family: Roboto, "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-family: 'Space Grotesk', "Helvetica Neue", Helvetica, Arial, sans-serif;
}

button, a {
  outline: 0 !important;
}

.highlight-link {
  padding:0 10px;
  background-color: $highlight-color;
  color: #323232;
  font-weight: 700;
}

.ellipsis {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.App {
  .App-shell {
    //height: 100vh;
    //transform: scale(.9);
  }
}

$hheight: 67px;
section {
  //padding: 100px 0;
  padding-top: $hheight;

  h2.section-heading {
    font-size: 40px;
    margin-top: 0;
    margin-bottom: 15px
  }

  h3.section-subheading {
    font-size: 16px;
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    text-transform: none;
    font-style: italic;
    font-weight: 400;
    margin-bottom: 75px
  }
}

@media (min-width: 768px) {
  section {
    //padding: 150px 0;
    padding-top: $hheight;
  }
}


.intro-section {
  padding: 100px 0;
  animation-name: fadeIn;
  animation-fill-mode: both;
  animation-duration: 500ms;
}

section {

  padding-bottom: 80px;

  h1 {
    font-family: Montserrat, Roboto, "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-weight: bold;
    letter-spacing: -1px;
  }
}

html {
  @include fluid-type(600px, 1200px, 13px, 18px);
}
.px-list {
  .px-list-item {
    border-bottom: 1px solid rgba(0, 0, 0, .1);
    &:last-child {
      border-bottom: 0;
    }
  }

  &.stripped .px-list-item {
    border-bottom: 0;
    &:nth-child(even) {
      background-color: rgba(0, 0, 0, .1);
    }
  }

  .px-list-item.px-list-item-hoverable:hover {
    background-color: rgba(188, 206, 235, 0.67);
  }
}