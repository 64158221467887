@import "../../layout/application/animations";
.board-wrapper {
  padding: 20px;
}

.main-board {

}

.not-your-turn-to-play {
  font-weight: 700;
  padding: 10px 20px;
  color: #FFF;
  display: inline-block;
  border-bottom: 2px solid #2e2e2e;
}

.player-tile-image {
  background-color: #FC0;
  background-image: url('../../assets/placeholder.user.png');
  animation-duration:600ms;
  cursor: pointer;
  position: relative;
  border-radius: 90px;
  height: 150px;
  width: 150px;
  border: 4px solid #FFF;
  background-position: 50% 50%;
  background-size: 120% auto;
  margin:auto;
  transition: border 300ms ease-out;
  &.selected {
    border: 10px solid #008000;
  }
}
.player-chip {
  position: absolute;
  top: 0;
  right: 0;
  background-color: #008000 ;
  overflow:hidden;
  border-radius: 34px;
  height: 34px;
  width: 34px;
  line-height: 34px;
  display: flex;
  justify-content: center;
  align-items: center;
  span {
    font-size: 28px;
    line-height: 34px;
    font-weight: 800
  }
}

.board {
  $size: 45px;
  $bcolor: rgba(0, 0, 0, .3);
  $br: 5px;
  width: 98%;
  margin: 20px auto;
  box-shadow: 0 2px 20px rgba(0, 0, 0, .4);
  border-radius: $br;

  background-color: #CCC;

  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome and Opera */



  > .row {
    margin-left: 0;
    margin-right: 0;
    border-bottom: 2px solid rgba(0, 0, 0, .2);
    border-top: 1px solid rgba(255, 255, 255, .1);

  }
  .row:first-child {
    border-top-left-radius: $br;
    border-top-right-radius: $br;
    border-top: 0;
    //border-bottom: 0;
  }
  .row:last-child {
    border-bottom-left-radius: $br;
    border-bottom-right-radius: $br;
    //border-top: 0;
    border-bottom: 0;
  }

  .player-row {
    background-color: #011d13;
    color: #FFF;
  }
  .score-row {
    color: #DDD;
    text-shadow: 0 0 1px rgba(0, 0, 0, .6);
    background: url(../../assets/tablefabric.jpg);
    background-size: 100px auto;
    .score-cell{
      &:hover {
        background-color: rgba(0, 0, 0, .1);
      }
      cursor: pointer;
    }
  }
  .total-row {
    background-color: #F4F4F4;
    color: #333;
  }
  @for $i from 1 through 30 {
    //.row:nth-child(#{$i}) {
    //
    //}
    .score-row:nth-child(#{$i}) {
      .score-cell-wrapper {
        .score-cell {
          &.highlight:not(.played) {
            span {
              animation-delay: #{$i * 150}ms;
            }
          }
        }
      }
    }

  }

  .score-title, .score-cell {
    padding: 0;
    text-align: center;
    //position: relative;

    display: block;
    //padding: 0 10px;
    line-height: $size;
    height: $size;
    font-weight: bold;
    font-size: 18px;
    > span {
      display: block;
      //padding: 0 10px;
      //line-height: $size;
      height: 100%;
      width: 100%;
      //cursor: pointer;
      //font-weight: bold;
      //font-size: 18px;
    }
  }
  .score-title {
    //background-color: rgba(255, 255, 255, .05);
    text-align: left;
    padding: 0 20px;
    //padding-right: 30px;
  }
  .annotation {
    position: absolute;
    top: 0;
    right: 10px;
    text-align: right;
    font-weight: normal !important;
    //text-shadow: 0 -1px 1px rgba(0, 0, 0, .3), 0 1px 0 #FFF!important;

    color: #7ab19c;
    font-size: 14px !important;
  }





  .score-cell-wrapper {
    display: flex;
    .score-cell {
      &.highlight:not(.played) {
        span {
          //animation: 3000ms showPlayable ease-out ;
          //animation-iteration-count: infinite;
          //animation-fill-mode: both;
          margin: 5px;
          height: calc(100% - 10px);
          width: calc(100% - 10px);
          border-radius: 3px;
          background-color: rgba(255,255,255,.1);
        }
      }

      //&.played {
      //  opacity: .3;
      //}
      flex-grow: 1;
      background-color: rgba(255, 255, 255, .05);
      border-left: 1px solid rgba(255, 255, 255, .2);
      border-right: 1px solid rgba(0, 0, 0, .1);
      &.name-wrapper {
        background-color: transparent;
        border-left: 0 !important;
        border-right: 0;
      }
      .animated {
        animation-duration: 300ms;
      }

      &:first-child {
        border-left: 1px solid rgba(255, 255, 255, .1);
      }
      &:last-child {
        border-right: 0;
      }
    }
  }

  @for $i from 1 through 30 {
    //.row:nth-child(#{$i}) {
    //
    //}
    .score-cell-wrapper {
      .score-cell {
        &.highlight:not(.played) {
          span {
            animation-delay: #{$i * 50}ms;
          }
        }
      }
    }
  }

  &.crimson {

    padding: 5px;
    background: url(../../assets/tablefabric-red.jpg);
    background-size: 100px auto;

    .player-row {
      background-color: rgba(178, 144, 10, 0.1);
      color: #FFF;
    }

    > .row {
      margin-left: 0;
      margin-right: 0;
      border-bottom: 2px solid rgba(47, 6, 6, 0.47);
      border-top: 1px solid rgba(112, 86, 85, 0.27);

    }



    .score-row {
      color: #DDD;
      text-shadow: 0 0 1px rgba(0, 0, 0, .6);
      background: rgba(0,0,0,.2);
      background-size: 100px auto;
    }
    .annotation {
      position: absolute;
      top: 0;
      right: 0;
      text-align: right;
      font-weight: normal !important;
      //text-shadow: 0 -1px 1px rgba(0, 0, 0, .3), 0 1px 0 #FFF!important;

      color: rgba(255, 255, 255, .3);
      font-size: 14px !important;
    }

    .total-row {

      border-top: 0;    border-bottom: 1px solid rgb(200, 200, 200);
    }

    .score-cell-wrapper {
      display: flex;
      .score-cell {
        flex-grow: 1;
        background-color: rgba(0, 0, 0, .05);
        border-left: 1px solid rgba(255, 255, 255, .05);
        border-right: 1px solid rgba(0, 0, 0, .05);

        &.highlight {
          background-color: rgba(255,230, 0, .1);
        }
        &.name-wrapper {
          background-color: transparent;
          border-left: 0 !important;
          border-right: 0;
        }
        .animated {
          animation-duration: 300ms;
        }
        &:hover {
          background-color: rgba(0, 0, 0, .1);
        }
        &:first-child {
          border-left: 1px solid rgba(255, 255, 255, .05);
        }
        &:last-child {
          border-right: 0;
        }
      }
    }
  }
}