.ym-tooltip {
  position: relative;

  .ym-tooltip-label{
    position: absolute;
    display: none;
    bottom:40px;
    margin-left: -80px;
    min-width: 160px;


    padding: 0 5px;
    background-color: rgba(0,0,0,.8);
    color:#DDD;
    font-size: 15px;
    line-height: 30px;
    border-radius: 3px;
    animation-duration: 250ms;
  }
  &:hover {
    .ym-tooltip-label {
      display: inline-block;
    }
  }
}