/*base code*/
.animated {
  animation-duration: 300ms;
  animation-fill-mode: both;
}

.animated.infinite {
  animation-iteration-count: infinite;
}

.delay-300ms {
  animation-delay: 300ms;
}

.animated.hinge {
  animation-duration: 2s;
}

/*the animation definition*/

@keyframes rotateTo45 {
  0% {
    opacity: 0;

    transform-origin: 50% 50%;

    transform: rotate(-45deg);
  }
  100% {
    opacity: 1;

    transform-origin: 50% 50%;

    transform: rotate(45deg)
  }
}

@keyframes rotateTo-45 {
  0% {
    opacity: 0;

    transform-origin: 50% 50%;

    transform: rotate(45deg);
  }
  100% {
    opacity: 1;

    transform-origin: 50% 50%;

    transform: rotate(-45deg)
  }
}

@keyframes helloFromLeft {
  0% {
    opacity: 0;
    transform-origin: 50% 50%;
    transform: translateX(-400px);
  }
  100% {
    opacity: 1;
    transform: translateX(0px) ;
  }
}

//
//@keyframes rotateUpRight {
//    0% {
//
//        transform-origin: right bottom;
//
//        transform: rotate3d(0, 0, 1, -90deg)
//    }
//    100% {
//
//        transform-origin: right bottom;
//
//        transform: none
//    }
//}
@keyframes rotateUpRight {
  0% {

    transform-origin: right bottom;

    transform: rotate3d(0, 0, 1, -90deg)
  }
  100% {

    transform-origin: right bottom;

    transform: none
  }
}

.rotateUpRight {

  animation-name: rotateUpRight
}

/*the animation definition*/
@keyframes flipInY {
  0% {

    transform: perspective(400px) rotate3d(0, 1, 0, 90deg);

    transition-timing-function: ease-in;
    opacity: 0
  }
  40% {

    transform: perspective(400px) rotate3d(0, 1, 0, -20deg);

    transition-timing-function: ease-in
  }
  60% {

    transform: perspective(400px) rotate3d(0, 1, 0, 10deg);
    opacity: 1
  }
  80% {

    transform: perspective(400px) rotate3d(0, 1, 0, -5deg)
  }
  100% {

    transform: perspective(400px)
  }
}

.flipInY {

  backface-visibility: visible !important;

  animation-name: flipInY
}


@keyframes fadeInUpShort {
  from {
    opacity: 0;
    transform: translate3d(0, 40px, 0);
  }

  to {
    opacity: 1;
    transform: none;
  }
}

.fadeInUpShort {
  animation-name: fadeInUpShort;
}

@keyframes fadeInRightShort {
  from {
    opacity: 0;
    transform: translate3d(40px, 0, 0);
  }

  to {
    opacity: 1;
    transform: none;
  }
}

.fadeInRightShort {
  animation-name: fadeInRightShort;
}

@keyframes layerOn {
  from {
    opacity: 0;
    transform: scale(1.2, 1.2);
  }

  to {
    opacity: 1;
    transform: scale(1, 1);
  }
}

.fadeInUpShort {
  animation-name: fadeInUpShort;
}




@keyframes cruz-rotation {
  0% {
    transform:  translateZ(1px)  rotateX(0deg) rotateY(0deg);
  }
  25% {
    transform:  translateZ(1px)  rotateX(5deg) rotateY(30deg);
  }
  50% {
    transform:  translateZ(1px)  rotateX(0deg) rotateY(0deg);
  }
  75% {
    transform: translateZ(1px)   rotateX(-5deg) rotateY(-30deg);
  }
  100% {
    transform:  translateZ(1px)  rotateX(0deg) rotateY(0deg);
  }
  //0% {
  //  transform:  rotateX(0deg) rotateY(0deg);
  //}
  //10% {
  //  transform:  rotateX(10deg) rotateY(10deg);
  //}
  //20% {
  //  transform:  rotateX(0deg) rotateY(0deg);
  //}
  //30% {
  //  transform:  rotateX(-10deg) rotateY(-20deg);
  //}
  //40% {
  //  transform:  rotateX(0deg) rotateY(0deg);
  //}
  //50% {
  //  transform:  rotateX(10deg) rotateY(10deg);
  //}
  //60% {
  //  transform:  rotateX(0deg) rotateY(0deg);
  //}
  //70% {
  //  transform:  rotateX(-10deg) rotateY(-40deg);
  //}
  //80% {
  //  transform:  rotateX(0deg) rotateY(0deg);
  //}
  //90% {
  //  transform:  rotateX(10deg) rotateY(10deg);
  //}
  //100% {
  //  transform:  rotateX(0deg) rotateY(0deg);
  //}
}

@keyframes globe-rotation {
  0% {
    transform: translateZ(-10px) scale(1.2) rotateX(-40deg) rotateY(1deg);
  }
  100% {
    transform: translateZ(-10px)  scale(1.2) rotateX(-40deg) rotateY(360deg)
  }
}

@keyframes globe-rotation-tw {
  0% {
    transform: translateZ(-10px)   scale(1.2) rotateY(30deg) rotateX(1deg);
  }
  100% {
    transform: translateZ(-10px)  scale(1.2) rotateY(30deg) rotateX(360deg)
  }
}

@keyframes fill-elapsed-time {
  0% {
   width: 0;
  }
  100% {
    width: 100%;
  }
}


@keyframes showPlayable {
  0% {
    background-color: rgba(255,255,255,0);
  }
  30% {
    background-color: rgba(255,255,255,.1);
  }
  50% {
    background-color: rgba(255,255,255,0);
  }
  100% {
    background-color: rgba(255,255,255,0);
  }
}


