.podium {



  .avatar {
    $size:90px;
    position: absolute;
    left: $size/-2;
    top: -80px;
    margin-left: 50%;
  }
  .others {
    border-radius: 4px;
    overflow: hidden;
    .player {
      line-height: 50px;
      height: 50px;
      background-color: #16412d;
      border-bottom: 1px solid #123424;
      display: flex;
      &:after {
        clear: left;
        content: "";
        display: block;
      }
      //margin-top: 2px;

      .avatar-wrapper {
        //float: left;
        //flex: auto;
        //width: 80px;
        //position: relative;
        //height: 40px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        height: 100%;
      }
      .avatar {
        $size: 30px;
        width: $size;
        height: $size;
        border-radius: $size/2;
        //top: 4px;
        //left: 20px;
        position: relative;
        left: auto;
        top: auto;
        margin: auto;
        border: 0;
      }
      .name {
        //float: left;

        flex: auto;
        //width: calc(50% - 40px);
        height: 40px;
        font-weight: bold;
      }
      .score {

        text-align: center;
        flex: auto;
        height: 40px;
        padding: 0 10px;
      }
    }
  }
  .leaders {
    position: relative;
    height: 280px;
    .avatar {
      $size:90px;
      width: $size;
      height: $size;
      border-radius: $size/2;
      //background: url(http://api.sunny.fr/images/sleeping.jpg);
      background-size: cover;
      position: absolute;
      left: $size/-2;
      top: -80px;
      margin-left: 50%;
      //border: 5px solid #039BE5;
      animation-duration: 1s;
      //animation-delay: 100ms;
    }
    .player {
      .wrapper {position: relative}
      text-align: center;
      background-color: #DDD;
      width: 33%;
      height: 160px;
      position: absolute;
      bottom:0;
      left:0;
      border-top-right-radius: 4px;
      border-top-left-radius: 4px;
      color: #fff;
      text-shadow: 0 1px 2px rgba(0,0,0,.4);

      .avatar {
        box-shadow: 0 10px 20px rgba(0,0,0,.7);
        &:before {
          content:"";
          display: block;
          position: absolute;
          top:-8px;
          left: -22.5px;
          width: 124px;
          height: 104px;
          background: url(../../../assets/images/golden-crown.png) 50% 50% no-repeat;
        }
      }

      &:nth-child(1) {
        height: 220px;
        left: 33%;
        background-color: #FFB300;
        width: 34%;

      }
      &:nth-child(2) {
        height: 185px;
        left: 0;
        background-color: #BDBDBD;
        .avatar {
          &:before {
            background: url(../../../assets/images/silver-crown.png) 50% 50% no-repeat;
          }
        }
      }
      &:nth-child(3) {
        left: 67%;
        background-color: #BF360C;
        .avatar {
          &:before {
            background: url(../../../assets/images/bronze-crown.png) 50% 50% no-repeat;
          }
        }
      }


      .name {
        margin-top: 20px;
        display: block;
        font-weight: bold;
        font-size: 20px;
      }
      .score {
        margin-top: 30px;
        font-size: 30px;
      }
    }
  }
}