.player-list {
  padding: 10px;
  &:after {
    display: block;
    content:'';
    clear: left;
  }
  .player-item {
    float:left;
    cursor: pointer;
    position: relative;
    width: 160px;
    padding: 10px;
    //border:1px solid #DDD;
    text-align: center;
    color:#666;
    .action-btn {
      position: absolute;
      top: -5px;
      right: 10px;

      button {
        width:20px;
        height: 20px;
      }
    }
    p {
      margin-top: 10px;
    }
    &.active {
      .avatar {
        box-shadow: 0 0 5px rgba(0,0,0,.5);
      }
      p {font-weight: bold}
    }
  }
}