
.dice-placehoder {
  $size: 64px;
  display: block;
  background-color: #313131;
  width: $size;
  height: $size;
  object-fit: contain;
  border-radius: 4px;
  box-shadow: inset 0 0 5px rgba(0,0,0,.8);
}
.dice-flat {
  &, * {
    box-sizing: border-box;
  }
  $size: 64px;
  $dot-size: 12px;
  [class^="face"] {
    //margin: 16px;
    padding: 4px;

    background-color: #e7e7e7;
    width: $size;
    height: $size;
    object-fit: contain;

    box-shadow:
            inset 0 5px white,
            inset 0 -5px #bbb,
            inset 5px 0 #d7d7d7,
            inset -5px 0 #d7d7d7;

    border-radius: 10%;
  }

  .dot {
    display: block;
    width: $dot-size;
    height: $dot-size;
    border-radius: 50%;
    margin: 2px;

    background-color: #333;
    box-shadow: inset 0 3px #111, inset 0 -3px #555;

    //
    //background-color: #ff5c9c;
    //box-shadow: inset 0 3px #e5538b, inset 0 -3px #e25389;

  }



  .face-1 {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .face-2 {
    display: flex;
    justify-content: space-between;
  }

  .face-2 .dot:nth-of-type(2) {
    align-self: flex-end;
  }

  .face-3 {
    display: flex;
    justify-content: space-between;
  }

  .face-3 .dot:nth-of-type(2) {
    align-self: center;
  }

  .face-3 .dot:nth-of-type(3) {
    align-self: flex-end;
  }

  .face-4, .face-6 {
    display: flex;
    justify-content: space-between;
  }

  .face-4 .column, .face-6 .column {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .face-5 {
    display: flex;
    justify-content: space-between;
  }

  .face-5 .column {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .face-5 .column:nth-of-type(2) {
    justify-content: center;
  }
}



.dice, .side {
  transition: transform 0.4s ease;
}
.dice-wrapper {
  position: relative;
  height: 100px;
  width: 100px;
}
.dice {
  $size:  100px;

  transform: rotateX(-15deg) rotateY(45deg);
  transform-style: preserve-3d;
  transform-origin: 50% 50% 0 ;
  backface-visibility: hidden;

  width: $size;
  height: $size;
  //margin-top: -$size;
  //margin-left: -$size;
  position: absolute;
  //top: 50%;
  //left: 50%;
  top: 0;
  left: 0;

  &.up-2 {
    transform: rotateX(-15deg) rotateY(45deg) rotateZ(80deg);
  }

  &.up-3 {
    transform: rotateX(75deg) rotateY(180deg) rotateZ(45deg);
  }

  &.up-4 {
    transform: rotateX(75deg) rotateY(0deg) rotateZ(45deg);
  }

  &.up-5 {
    transform: rotateX(-15deg) rotateY(-45deg) rotateZ(-90deg);
  }

  &.up-6 {
    transform: rotateX(165deg) rotateY(45deg) rotateZ(0deg);
  }

  .side {
    display: block;
    width: $size;
    height: $size;
    position: absolute;

    background: white;
    background: radial-gradient(white, #ddd);
    border: solid 6px #ddd;
    border-radius: 8px;
    box-sizing: border-box;
    object-fit: contain;
  }

  .dot {
    //dots
    content: '';


    display: block;
    width: $size/4.5;
    height: $size/4.5;

    border-radius: 50%;
    margin: 4px;

    background-color: #333;
    box-shadow: inset 0 3px #111, inset 0 -3px #555;


  }

  .side-1 {
    transform: rotateX(90deg) translateZ(50px);
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .side-6 {
    transform: rotateX(-90deg) translateZ(50px);
    display: flex;
    justify-content: space-between;
    .column{
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }
  }

  .side-5 {
    transform: rotateY(90deg) translateZ(50px);
    display: flex;
    justify-content: space-between;
    .column {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      &:nth-of-type(2) {
        justify-content: center;
      }
    }

  }

  .side-2 {
    transform: rotateY(-90deg) translateZ(50px);
    display: flex;
    justify-content: space-between;

    .dot:nth-of-type(2) {
      align-self: flex-end;
    }
  }

  .side-4 {
    transform: translateZ(50px);
    display: flex;
    justify-content: space-between;

    .column{
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }
  }

  .side-3 {
    transform: rotateY(180deg) translateZ(50px);
    display: flex;
    justify-content: space-between;
    .dot:nth-of-type(2) {
      align-self: center;
    }

    .dot:nth-of-type(3) {
      align-self: flex-end;
    }
  }

  //@for $i from 1 through 6 {
  //  .side-#{$i}:after {
  //    content: "#{$i}";
  //
  //    display: block;
  //    width: 100%;
  //    height: 100%;
  //    text-align: center;
  //    line-height: 88px;
  //    font-size: 60px;
  //    color: #444;
  //  }
  //}
}





