$fa-font-path: "~font-awesome/fonts";
@import '~font-awesome/scss/font-awesome';
@import "~bootstrap/scss/bootstrap-reboot";
@import "~bootstrap/scss/bootstrap";

@import '~animate.css/animate.css';
@import "variables";
@import "animations";
@import "mixins";
@import "global";





