
.score-dialog {
  background-color: #333;
  color:#EEE;
}
.score-dialog-title {
  justify-content: center;
  align-items: center;
  display: flex;
  padding: 20px;
  font-size: 24px;
  text-align: center;
  font-weight: 700;
  text-shadow: 0 1px 2px rgba(0,0,0,.3);
}
.score-dialog-content {

}
