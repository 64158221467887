.next-player-wrapper {
  $size:80px;
  position: fixed;
  bottom: 30px;
  left: 30px;
  .player {
    padding: 10px;
    background-color: #111;
    display: inline-block;
    min-width: 290px;
    min-height: $size;
    border-radius: 4px;
    box-shadow: 0 2px 15px rgba(0,0,0,.3);
    animation-duration: 1s;
    //animation-delay: 500ms;
    .name {
      color:#FFF;
      font-size: 20px;
      margin-left: 110px;
    }
    .image-wrapper {
      position: relative;
    }
    .image {
      $size:90px;
      width: $size;
      height: $size;
      border-radius: $size/2;
      //background: url(http://api.sunny.fr/images/sleeping.jpg);
      background-size: cover;
      position: absolute;
      left: 0;
      top: -20px;
      border: 5px solid #039BE5;
      animation-duration: 1s;
      //animation-delay: 100ms;
    }
  }
}