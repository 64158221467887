$size: 100px;
$rotationX: 30deg;
$translationY: 0px;
$translateZ: 2px;
$offsetX: 25px;
$offsetY: 20px;
$button-anim-duration: 2000ms;


@keyframes waitingToBeSwiped {
  0% {
    transform: translateX($offsetX) translateY($offsetY) translateZ($translateZ);
  }
  30% {
    transform: translateX($offsetX + 10px) translateY($offsetY) translateZ($translateZ);
  }
  40% {
    transform: translateX($offsetX) translateY($offsetY) translateZ($translateZ);
  }
  100% {
    transform: translateX($offsetX) translateY($offsetY) translateZ($translateZ);
  }
}

@keyframes boardShowUp {
  0% {
    transform: rotateX(70deg) translateY(400px) translateZ(-100px);
  }
  30% {
    transform: rotateX(70deg) translateY(0) translateZ(-100px);
  }
  60% {
    transform: rotateX($rotationX) translateY(0);
  }
  100% {
    transform: rotateX($rotationX) translateY($translationY);
  }
}

@keyframes boardHideUp {
  0% {
    transform: rotateX($rotationX) translateY($translationY);
  }
  30% {
    transform: rotateX($rotationX) translateY(0);
  }
  70% {
    transform: rotateX(70deg) translateY(0) translateZ(-100px);
  }
  100% {
    transform: rotateX(70deg) translateY(400px) translateZ(-100px);
  }
}

.board-show-up {
  animation-name: boardShowUp;
  animation-fill-mode: both;
  animation-duration: 1000ms;
}

.board-hide-up {
  animation-name: boardHideUp;
  animation-fill-mode: both;
  animation-duration: 1000ms;
}

.call-dice-board-wrapper {
  $size: 80px;
  position: fixed;
  bottom: 30px;
  right: 30px;


  .call-dice-board {
    //padding: 10px;
    background-color: #111;
    display: inline-block;
    height: $size;
    width: $size;
    border-radius: $size /2;
    box-shadow: 0 2px 15px rgba(0, 0, 0, .3);
    animation-duration: 1s;
    perspective: 600px;
    transform-style: preserve-3d;
    transform-origin: 50% 50%;

  }

  .call-dice-board-dice {
    transform: scale(.45)  translateX(-10px) translateY(30px) translateZ(50px);

    transform-style: preserve-3d;
    //> div {
    //  box-shadow: 0 0 10px rgba(0,0,0,.8);
    //}
  }


}

.yms-canvas {
  height: 200px;
  perspective: 800px;
  //overflow: hidden;
  margin: auto;
  position: fixed;
  bottom: 0;

  //bottom: -200px;
  left: 0;
  width: 100%;
  z-index: 10;
  background-image: linear-gradient(rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, .3) 60%);
  transition: transform 300ms ease-out, opacity 300ms ease-out;
  opacity: 1;

  &.hidden {
    transform: translateY(200px);
    opacity: 0;
  }


  .yms-drag-container {
    width: 100%;
    //left: 15%;
    position: absolute;
    z-index: 1;
    user-select: none;
    //background-color: rgba(0, 0, 0, .3);
    //translateY(90px) translateZ(-50px)
    //transform: rotateX($rotationX) translateY(#{$translationY + 90}) translateZ(-50px);
    transform: translateZ(10px) translateY(120px);
    transform-style: preserve-3d;
    transform-origin: 50% 50%;
    //height: 100%;
    height: 80px;
    backface-visibility: hidden;
  }


  .yms-game-state {
    opacity: .8;
    border-radius: 3px;
    //border: 3px solid #222;
    background-color: rgba(0, 0, 0, .05);
    text-transform: uppercase;
    padding: 5px 30px;
    color: #222;
    font-weight: bold;
    text-shadow: 0 -1px 0px rgba(255, 255, 255, .2);
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, .2), inset 0 -1px 1px rgba(0, 0, 0, .2);;
    //background-color: ;
  }

  //.yms-game-state,.yms-board-toggle {
  //  transform-style: preserve-3d;
  //  position: absolute;
  //  top: 0;
  //  z-index: 2;
  //
  //  //transform: rotateX(-35deg) translateY(-35px) translateX(-50%);
  //  transform: rotateX(-45deg) translateZ(5px) translateY(-40px) translateX(-50%);
  //  transform-origin: 100% 100%;
  //}
  //.yms-game-state {
  //  left: 50%;
  //  background-color: #eeeeee;
  //  border-radius: 10px;
  //  border: 3px solid #323232;
  //  padding: 5px 10px;
  //  color: #323232;
  //  font-weight: 700;
  //}
  //.yms-board-toggle {
  //  right: 30px;
  //  transform: rotateX(-45deg) translateZ(5px) translateY(-40px) ;
  //}

  .yms-drag-handler {
    transform: translateX($offsetX) translateY($offsetY) translateZ(#{$translateZ});
    position: absolute;
    z-index: 2;
    user-select: none;
    pointer-events: none;
    display: inline-block;
    width: 150px;

    &.waiting-to-be-swiped {
      animation-name: waitingToBeSwiped;
      animation-duration: $button-anim-duration;
      animation-timing-function: ease-in-out;
      animation-iteration-count: infinite;
    }
  }

  .yms-drag-shadow {
    transform: translateX(#{$offsetX - 4}) translateY(#{$offsetY - 3}) translateZ(#{$translateZ - 2});
    position: absolute;
    z-index: 1;
    user-select: none;
    pointer-events: none;
    background-color: rgba(0, 0, 0, .2);
    border-radius: 20px; //25px;
    height: 52px;
    width: calc(100% - #{$offsetX * 2});
    box-shadow: inset 0 0 15px rgba(0, 0, 0, .6);
    border-top: 1px solid rgba(255, 255, 255, .2);
    border-bottom: 1px solid rgba(255, 255, 255, .2);
  }

  .yms-drag-button {
    transform: translateX(0) translateY(2px);
    opacity: 1;
    //position: absolute;
    //z-index: 1;
    user-select: none;
    pointer-events: none;
    border-radius: 20px; //25px;
    padding: 10px 20px;
    //box-shadow: 0 2px 8px rgba(0,0,0,.4);
    box-shadow: 1px 2px 3px rgba(0, 0, 0, 0.4);

    &.pressed {
      box-shadow: 0 1px 1px rgba(0, 0, 0, 0.2);
    }

    background-color: #E9E9E9;
    border: 0;
    color: #333;
    font-weight: 800;
    width: 150px;
    transition: transform 300ms ease-out, opacity 300ms ease-out, box-shadow 300ms ease-out;
  }

  .yms-scene {
    transform: rotateX($rotationX) translateY($translationY);

    //animation-name: boardShowUp;
    //animation-fill-mode: both;
    //animation-duration: 2000ms;
    //animation-direction: reverse;
    //transform: rotateX(70deg) translateY(400px) ;

    transform-style: preserve-3d;
    transform-origin: 50% 50%;
    height: 100%;
    width: 70%;
    margin-left: 15%;
    backface-visibility: hidden;
  }


  .yms-dice-board {
    position: absolute;
    width: 100%;
    height: 200px;
    border-radius: 30px;
    perspective-origin: 50% 50%;
    transform-origin: 50% 50%;
    //transform: translateX(0px) translateY(40px) rotateX(50deg);
    transform: translateX(0px) translateY(0px) translateZ(0px) rotateX(0);
    border: 8px solid #212121;
    background: url("http://yamsy.sunny.fr/static/media/tablefabric.69f04217.jpg");
    background-size: 30% auto;
    backface-visibility: hidden;
    //box-shadow: inset 0 0 20px rgba(0,0,0,.3), 0 10px 40px rgba(0,0,0,.3);
    box-shadow: inset 0 0 20px rgba(0, 0, 0, 0.3), 0 10px 40px rgba(0, 0, 0, 0.3), inset 0 0 3px rgba(94, 255, 192, .9);
  }


  .yms-dice-container {
    transform-style: preserve-3d;
    transform: translate(0, 0);
    --dice-background-color-1: #FFF;
    --dice-background-color-2: #c7c7c7;
    --dot-color: #333;
    --dice-hover-animation-duration: 200ms;

    &.picked {
      --dice-background-color-1: #1976D2;
      --dice-background-color-2: #1565C0;
      --dot-color: #FFF;
    }

    &:hover {
      //--dice-background-color-1: #1976D2;
      //--dice-background-color-2: #1565C0;
      //--dot-color: #FFF;

      &:hover .yms-dice-shadow:after {
        transform: scale(1.75) rotateZ(45deg);
      }
    }
  }

  .yms-dice {
    transform-style: preserve-3d;
    display: inline-block;
    width: $size;
    height: $size;
    position: absolute;
    cursor: pointer;

  }

  .yms-dice-shadow {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    transform: translateZ(-25px);

    &:after {
      content: "";
      filter: blur(5px);
      position: absolute;
      border-radius: 20%;
      background-color: rgba(0, 0, 0, .1);
      display: inline-flex;
      height: 100%;
      width: 100%;
      transform: scale(1.25) rotateZ(45deg);
      transition: transform ease-out 400ms;
    }
  }

  .yms-dice-placeholder {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    position: absolute;

    &:after {
      content: "";
      position: absolute;
      border-radius: 50%;
      background-color: rgba(0, 0, 0, 1);
      box-shadow: 0 0 30px black, 0 0 30px black, 0 0 30px black, 0 0 30px black, 0 0 30px black; //, 0 0 30px black, 0 0 30px black;
      display: inline-flex;
      height: 100%;
      width: 100%;
      transform: translateZ(-25px);
    }
  }


  .yms-dice-faces {
    transform-origin: 50% 50%;
    width: $size;
    height: $size;
    position: absolute;

    .yms-side {
      display: flex;
      width: $size;
      height: $size;
      position: absolute;
      //background: white;
      transition: background-color var(--dice-hover-animation-duration) ease-out, box-shadow var(--dice-hover-animation-duration) ease-out;
      //background: radial-gradient(var(--dice-background-color-1), var(--dice-background-color-2));
      //border: 6px solid var(--dice-background-color-2);

      padding: 6px;
      background-color: var(--dice-background-color-1);
      box-shadow: inset 0 0 6px 4px var(--dice-background-color-2);

      border-radius: 8%;
      box-sizing: border-box;
      object-fit: contain;
    }

    .yms-dot {
      //dots
      content: '';
      display: block;
      width: $size/4.5;
      height: $size/4.5;
      border-radius: 50%;
      margin: 4%;
      transition: background-color var(--dice-hover-animation-duration) ease-out, box-shadow var(--dice-hover-animation-duration) ease-out;
      background-color: var(--dot-color);
      //box-shadow: inset 0 3px #111, inset 0 -3px #555;
      box-shadow: inset 0 0 3px rgba(0, 0, 0, .1);
    }

    .yms-side-1 {
      transform: rotateX(90deg) translateZ(50px);
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .yms-side-6 {
      transform: rotateX(-90deg) translateZ(50px);
      display: flex;
      justify-content: space-between;

      .yms-column {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
      }
    }

    .yms-side-5 {
      transform: rotateY(90deg) translateZ(50px);
      display: flex;
      justify-content: space-between;

      .yms-column {
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        &:nth-of-type(2) {
          justify-content: center;
        }
      }

    }

    .yms-side-2 {
      transform: rotateY(-90deg) translateZ(50px);
      display: flex;
      justify-content: space-between;

      .yms-dot:nth-of-type(2) {
        align-self: flex-end;
      }
    }

    .yms-side-4 {
      transform: translateZ(50px);
      display: flex;
      justify-content: space-between;

      .yms-column {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
      }
    }

    .yms-side-3 {
      transform: rotateY(180deg) translateZ(50px);
      display: flex;
      justify-content: space-between;

      .yms-dot:nth-of-type(2) {
        align-self: center;
      }

      .yms-dot:nth-of-type(3) {
        align-self: flex-end;
      }
    }

    .yms-side-x {
      transform: rotateX(0deg) translateZ(0px);
      background-color: var(--dice-background-color-2);
      box-shadow: 0 0 0 transparent;
    }

    .yms-side-y {
      transform: rotateX(90deg) translateZ(0px);
      background-color: var(--dice-background-color-2);
      box-shadow: 0 0 0 transparent;
    }

    .yms-side-z {
      transform: rotateX(90deg) rotateY(90deg) translateZ(0px);
      background-color: var(--dice-background-color-2);
      box-shadow: 0 0 0 transparent;
    }
  }
}
