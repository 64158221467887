.pad {
  $size: 60px;
  margin-left: 0;
  margin-right: 0;
  border-radius: 5px;
  background-color: #444;
  color:#DDD;
  overflow: hidden;
  box-shadow: 0 1px 5px rgba(0,0,0,.3);

  > .row {
    margin-left: 0;
    margin-right: 0;
  }
  .padkey {
    &.single {
      min-width: 200px;
    }
    padding: 0;
    font-weight: bold;
    font-size: 20px;
    line-height: $size;
    height: $size;
    //border-right: 1px solid #666;
    border-bottom: 1px solid #666;

    text-align: center;
    cursor: pointer;
    &:hover {
      background-color: #494949;
    }
  }

}
.padvalue {
  margin-top:40px;
  line-height: 60px;
  background-color: #444;
  text-align: center;
  border-radius: 5px;
  color:#DDD;
  font-size: 20px;
  box-shadow: 0 1px 5px rgba(0,0,0,.3);
}